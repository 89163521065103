import FetchRequest from "@/api/FetchRequest";

interface loginBody {
  username: string;
  password: string;
  code: string;
  uuid: string;
}
// 登录方法
export const login = (
  username: string,
  password: string,
  code: string,
  uuid: string
) => {
  const data: loginBody = {
    username,
    password,
    code,
    uuid,
  };
  return FetchRequest.post("/login", JSON.stringify(data), false);
};

// 注册方法
export const register = (data: any) =>
  FetchRequest.post("/register", JSON.stringify(data), false);

// 退出方法
export const logout = () => FetchRequest.post("/logout", "", true);

// 获取验证码
export const getCodeImg = () => FetchRequest.get("/captchaImage", false);

// 获取手机验证码
export const getsmsCode = (data: any) => FetchRequest.post("/send-sms-code",JSON.stringify(data), false);
// 验证码登录
export const loginsmsCode = (data: any) => FetchRequest.post("/sms-login",JSON.stringify(data), false);
// 微信登录
export const loginWx = (code:string,state:string) => FetchRequest.get("/wx-login"+"?code="+code+'&state='+state, false);
// 微信绑定手机号
export const loginWxmobile = (mobile:string,smsCode:string) => FetchRequest.get("/api/sys/users/bind—mobile"+"?mobile="+mobile+'&smsCode='+smsCode, true);